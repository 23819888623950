import { AppUsernotificationsSlideoverList } from '#components'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppUsernotificationsSlideoverListProps = ComponentProps<typeof AppUsernotificationsSlideoverList>

export type UsernotificationsUtils = ReturnType<typeof useUsernotificationsUtils>

// view:

export const VIEW_SETTINGS_NOTIFICATIONS: ViewSettings<Usernotification> = {
  tableName: 'usernotifications',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'notificationType',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'notificationName',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'notificationDescription',
      label: 'Descripción',
    },
    ...COLUMNS_METADATA,
  ],
}

export const useUsernotifications = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Usernotification>('usernotifications')

  const slideoverOpenList = (props: AppUsernotificationsSlideoverListProps) => {
    slideover.open(AppUsernotificationsSlideoverList, {})
  }

  // actions:

  const { user } = useUserSession()

  const getDropdownItems = (row: Usernotification, refresh: () => Promise<void>, utils: UsernotificationsUtils, dropdownItemsAction: DropdownItem[][] = []): DropdownItem[][] => [
    [
      {
        label: row.read ? 'Marcar como no leído' : 'Marcar como leído',
        icon: row.read ? 'i-mdi-check-circle' : 'i-mdi-circle-outline',
        click: async () => {
          await onSubmitUpdate({ id: row.id, read: !row.read })
          slideover.close()
          await refresh()
        },
      },
    ],
    ...dropdownItemsAction,
  ]

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    slideoverOpenList,
    modal,
    modalOpenDelete,
    getDropdownItems,
  }
}

export const useUsernotificationsUtils = ({ $users }: {
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsUsernotifications,
    type: optionsNotifications.type,
    userId: computed(() => $users.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsNotifications.type, 'value'),
    userId: computed(() => toMapByKey($users.data.value, 'id')),
  }

  return { options, maps }
}
